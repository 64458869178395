<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                {{ $store.state.sysenv.codePropagated.name }}
                <small>Icons</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
                <div class="j_search type01">
                  <input v-model="search" type="search" name="q" placeholder="Filter and search" />
                  <button type="submit">
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </div>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="iconCode"
            hide-actions
            :headers="headers"
            :items="icons"
            :search="search"
            :icon="true"
            @filtered="onFiltered"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus(props)">
                <td v-if="codePropagated.code === 'ALL'">{{ props.item.catName }}</td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td style="text-align: center;">
                  <img :src="props.item.filePath" />
                </td>
                <td>{{ props.item.name }}</td>
                <td>{{ formatSize(props.item.width, props.item.height) }}</td>
                <td>{{ props.item.fileName }}</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Icon"
        ref="formHandler"
        :formMode="formode"
        :resetable="resetable()"
        :opened.sync="modOpen"
        :modalType="'type00'"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.name"
                required
                label="Icon Title"
                placeholder="Input/Edit Icon Title"
                :rules="defaultRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <div class="file-object-wrapper">
                <v-text-field
                  v-model="files"
                  hidden
                  required
                  style="display:none;"
                  class="file-object-input"
                  :rules="fileRules"
                ></v-text-field>
                <span class="label">File Upload</span>
                <vue-dropzone
                  class="j_dropzone"
                  ref="myVueDropzone"
                  @vdropzone-error="dropError"
                  @vdropzone-complete="afterComplete"
                  :options="dropzoneOptions"
                  :include-styling="false"
                  id="customdropzone"
                  :useCustomSlot="true"
                  v-on:vdropzone-thumbnail="thumbnail"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">Choose a SVG file or drag it here</h3>
                  </div>
                </vue-dropzone>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import vue2Dropzone from "vue2-dropzone"
import "vue2-dropzone/dist/vue2Dropzone.min.css"

export default {
  name: "sysenv-icons",
  components: {
    JFormModal,
    vueDropzone: vue2Dropzone
  },
  data: () => ({
    systemService: null,
    image: "",
    modOpen: false,
    search: "",
    selected: [],
    selectedIndex: -3,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      {
        type: "text",
        text: "Group Name",
        align: "left",
        value: "catName",
        width: 50,
        sortable: false
      },
      {
        type: "conf",
        text: "No",
        align: "center",
        value: "",
        width: 50,
        sortable: false
      },
      {
        type: "text",
        text: "Icon",
        align: "center",
        value: "filepath",
        width: 50,
        sortable: false
      },
      {
        type: "text",
        text: "Title",
        align: "left",
        value: "name",
        width: 120,
        sortable: true
      },
      {
        type: "text",
        text: "Size",
        align: "left",
        value: "",
        width: 100,
        sortable: true
      },
      {
        type: "text",
        text: "File",
        align: "left",
        value: "fileName",
        sortable: true
      }
    ],
    icons: [],
    catCode: "",
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    files: [],
    width: 0,
    height: 0,
    defaultRules: [v => !!v || "Required"],
    nameRules: [
      v => !!v || "Name Required",
      // v => !!v && /^[A-Za-z0-9_]*$/.test(v) || 'Enter only English and numbers',
      v => (!!v && v.length >= 1) || "At least 1 characters",
      v => (!!v && v.length < 20) || "Less then 20 characters"
    ],
    fileRules: [v => !!v[0] || "file required"],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      maxFiles: 1,
      init: function () {
        this.hiddenFileInput.removeAttribute("multiple");
        this.on("addedfile", function (file) {
          if (!!this.files && this.files.length > 1)
            this.removeFile(this.files[0]);
        });
        this.on("maxfilesexceeded", function (file) {
          this.removeAllFiles();
          this.addFile(file);
        });
      },
      thumbnailWidth: 130,
      thumbnailHeight: 130,
      addRemoveLinks: false,
      uploadMultiple: false,
      acceptedFiles: "image/svg+xml",
      newFileChanged: false
      // previewTemplate: this.iconTemplate(),
    },
    yes: () => { }
  }),
  watch: {
    codePropagated: {
      handler(val) {
        this.headersChange(val);
        this.selectedIndex = -3;
        this.systemService.getIcons(val.code, this.setIcons);
        this.catCode = val.code;
        this.resetfiles();
      },
      deep: true
    }
  },
  created() {
    this.systemService = new SystemService();
  },
  mounted() {
    setTimeout(() => {
      this.systemService.getIcons(
        this.$store.state.sysenv.codePropagated.code,
        this.setIcons
      );
      this.catCode = this.$store.state.sysenv.codePropagated.code;
      this.headersChange(this.codePropagated);
    }, 200);
  },
  computed: {
    codePropagated: {
      get() {
        return this.$store.state.sysenv.codePropagated;
      },
      set(value) {
        this.$store.commit("sysenv/codePropagate", value);
      }
    }
  },
  methods: {
    setIcons(res) {
      this.modOpen = false;

      if (!res) res = [];
      this.icons = res;
      if (this.selectedIndex < -1) {
        //
      } else if (this.selectedIndex == -1) {
        this.setEdit(
          this.icons.findIndex(x => x.catCode === this.selectedItem.catCode)
        );
      } else {
        if (
          this.icons.findIndex(x => x.iconCode === this.selectedItem.iconCode) <
          0
        ) {
          if (this.icons.length > 0) {
            if (this.icons.length >= this.selectedIndex + 1) {
              // --
            } else {
              this.selectedIndex = this.icons.length - 1;
            }
            this.setEdit(this.selectedIndex);
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex);
        }
      }
    },
    onFiltered(items) {
      // Renumbering for the action of Drag & Drop and search filtering.
      let reNumbering = 0;
      items.forEach(_item => {
        this.icons.find(
          _item_ => _item_.iconCode == _item.iconCode
        ).no = ++reNumbering;
      });
    },
    onSorted(items) {
      items.forEach((item, index) => {
        item.no = index + 1;
      });
      // this.icons = items.slice()
    },
    add() {
      this.yes = () => {
        this.msgOpen = false;

        let formData = new FormData()
        this.files.forEach(f => {
          formData.append("icons", f)
        });

        // TODO: On multi file upload...
        let img = document.querySelector(".dz-image img")
        this.selectedItem.width = parseInt(img.naturalWidth)
        this.selectedItem.height = parseInt(img.naturalHeight)

        formData.set("catCode", this.selectedItem.catCode)
        formData.set("name", this.selectedItem.name)
        formData.set("width", this.selectedItem.width)
        formData.set("height", this.selectedItem.height)
        formData.set("fileExt", "svg")
        formData.set("desc", this.selectedItem.desc)

        this.systemService.putIcons(formData, res => {
          this.systemService.getIcons(this.catCode, this.setIcons)
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Icon?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      this.yes = () => {
        this.msgOpen = false;

        let formData = new FormData();
        this.files.forEach(f => {
          formData.append("icons", f);
        });

        // TODO: On multi file upload...
        let img = document.querySelector(".dz-image img");
        this.selectedItem.width = parseInt(img.naturalWidth);
        this.selectedItem.height = parseInt(img.naturalHeight);

        formData.set("iconCode", this.selectedItem.iconCode);
        formData.set("name", this.selectedItem.name);
        formData.set("width", this.selectedItem.width);
        formData.set("height", this.selectedItem.height);
        formData.set("fileExt", "svg");
        formData.set("desc", this.selectedItem.desc);
        formData.set("fileChanged", this.newFileChanged);

        this.systemService.updIcons(formData, res => {
          this.systemService.getIcons(this.catCode, this.setIcons);
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delIcons(this.selectedItem.iconCode, res => {
          this.systemService.getIcons(this.catCode, this.setIcons);
        });
        this.yes = () => { };
      };
      this.systemService.getIconRelated(
        this.selectedItem.catCode,
        this.selectedItem.iconCode,
        res => {
          if (res == 0) {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Action Approval";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message = "Do you want to delete selected icon?";
            this.msgInfo.button = [true, false, true];
            this.msgInfo.buttonText = ["Yes", "No", "Cancel"];
            this.msgInfo.buttonText[0] = "Delete";
            this.msgOpen = true;
          } else {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Can Not Delete";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message =
              "Related Menu Item have " +
              res +
              " of data. " +
              "Please delete relevant data first.";
            this.msgInfo.button = [false, false, true];
            this.msgOpen = true;
          }
        }
      );
    },
    propStatus(props) {
      this.selectedIndex = this.icons.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      var thumbnailFile = {};
      thumbnailFile.name = props.item.fileName;
      this.thumbnail(thumbnailFile, props.item.filePath);

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].iconCode === props.item.iconCode) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.files = [{ dummy: "dummy" }];
      this.modOpen = true;
    },
    setAdd() {
      this.selectedIndex = this.selectedIndex < -1 ? this.selectedIndex : -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];

      document.querySelector("#customdropzone").innerHTML = `
      <div class="dz-message">
        <div class="dropzone-custom-content">
          <h3 class="dropzone-custom-title">Choose a SVG file or drag it here</h3>
        </div>
      </div>
      `;
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.resetfiles();
      this.$refs.formHandler.formReset();
      this.modOpen = true;

      this.selectedItem.catCode = this.codePropagated.code;
    },
    setEdit(selectedIndex) {
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.icons[this.selectedIndex]);
      this.selected = [Object.assign({}, this.icons[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (!!this.selectedItem.name) return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].name != this.selectedItem.name ||
          !this.files[0].dummy
        )
          return true;
        return false;
      }
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.resetfiles();
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    resetfiles() {
      if (!!this.$refs.myVueDropzone) {
        this.$refs.myVueDropzone.removeAllFiles(true);
      }
      var editThumbnail = document.querySelector("#editThumbnail");
      if (!!editThumbnail) editThumbnail.remove();
      this.files = [];
    },
    isCodeValid(iconCode) {
      return !this.icons.find(item => item.iconCode == iconCode);
    },
    formatBytes(num) {
      if (typeof num !== "number" || isNaN(num)) {
        throw new TypeError("Expected a number");
      }

      var exponent;
      var unit;
      var neg = num < 0;
      var units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      if (neg) {
        num = -num;
      }

      if (num < 1) {
        return (neg ? "-" : "") + num + " B";
      }

      exponent = Math.min(
        Math.floor(Math.log(num) / Math.log(1000)),
        units.length - 1
      );
      num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
      unit = units[exponent];

      return (neg ? "-" : "") + num + " " + unit;
    },
    formatSize(width, height) {
      width = width.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      height = height.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return width + " X " + height;
    },
    selectedFile() {
      document.getElementById("addFile").click();
    },
    optionCheck() {
      if (this.formode == __C.FORM.EDIT_MODE_NEW) return true;
      else false;
    },
    afterComplete(file) {
      this.files = [];
      this.selectedItem.fileName = "";
      if (file.type == "image/svg+xml") {
        this.files.push(file);
      }
      this.selectedItem.fileName = file.name;
    },
    dropError(file, message, xhr) {
      if (file.type != "image/svg+xml")
        file.previewElement.children[3].innerText = "Only upload SVG file";
    },
    headersChange(val) {
      let catIndex = this.headers.findIndex(h => h.value === "catName");
      if (val.code === "ALL") {
        if (catIndex < 0)
          this.headers.unshift({
            type: "text",
            text: "Group Name",
            align: "left",
            value: "catName",
            width: 170,
            sortable: false
          });
      } else {
        if (catIndex >= 0) this.headers.shift();
      }
    },
    thumbnail: function (file, dataUrl) {
      var editThumbnail = document.querySelector("#editThumbnail");
      if (!!editThumbnail) editThumbnail.remove();

      var j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length;j < len;j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        this.newFileChanged = true;
        return setTimeout(
          (function (_this) {
            return function () {
              return file.previewElement.classList.add("dz-image-preview");
            };
          })(this),
          100
        );
      } else {
        document.querySelector("#customdropzone").innerHTML = `
          <div id="editThumbnail" class="dz-preview dz-file-preview">
            <div class="dz-image">
              <div data-dz-thumbnail-bg>
                <img id='previewImg' />
              </div>
            </div>
            <div class="dz-details">
              <div class="dz-size"><span data-dz-size></span></div>
              <div class="dz-filename"><span data-dz-name></span></div>
            </div>
            <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
            <div class="dz-error-message" style="color: red"><span data-dz-errormessage style="color: red"></span></div>
            <div class="dz-success-mark"><i class="fa fa-check"></i></div>
            <div class="dz-error-mark"><i class="fa fa-close"></i></div>
          </div>
        `;
        this.newFileChanged = false;

        var previewImg = document.querySelector("#previewImg");
        document.querySelector(".dz-filename span").innerText = file.name;
        previewImg.alt = file.name;
        previewImg.src = dataUrl;
      }
    }
  }
};
</script>
